import * as Yup from "yup";
import {
    Mutation,
    MutationGenerateGenericPresignedUrlArgs,
} from "../../../generated/nest-graphql";
import { Form, Formik } from "formik";

import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { useMutation } from "@apollo/client";
import { GENERATE_GENERIC_PRESIGNED_URL } from "../../../graphql/mutations/generateGenericPresignedUrl";
import { useSimpleS3Upload } from "../../../hooks/useS3Upload";
import { TextField } from "../../Forms/fields/TextField";
import { SubmitButton } from "../../Buttons/SubmitButton";
import Webcam from "react-webcam";
import { Button, ButtonGroup } from "@material-ui/core";
import { useDispatch } from "../../../contexts/snackbar-context";
import { showSuccessAlert } from "../../../actions";
import { Camera, FlipCameraIos, Cancel } from "@material-ui/icons";

export type FileFormValues = {
    caption: string;
    fileName: string;
    url: string;
    files: any[];
};

const fileFormValidationSchema = Yup.object().shape({
    caption: Yup.string().notRequired(),
    url: Yup.string().required("Required"),
    fileName: Yup.string().required("Required"),
});
export const PhotoForm: React.FC<{
    contactId: string;
    jobId: string;
    name: string;
    onSubmit: (values, helper) => void;
    initialValues: FileFormValues;
}> = ({ contactId, jobId, initialValues, onSubmit, name }) => {
    return (
        <Formik<FileFormValues>
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={fileFormValidationSchema}
            validateOnMount={true}
        >
            {({ isValid, isSubmitting, values, setFieldValue, resetForm }) => {
                return (
                    <FileFormInternals
                        contactId={contactId}
                        jobId={jobId}
                        name={name}
                        values={values}
                        setFieldValue={setFieldValue}
                        isSubmitting={isSubmitting}
                        isValid={isValid}
                        resetForm={resetForm}
                    />
                );
            }}
        </Formik>
    );
};

const WebcamCapture = ({ onCapture, fileName }: { fileName: string, onCapture?: (file: File) => void }) => {
    const [isFront, setIsFront] = useState(true);
    const [image, setImage] = useState<string>();
    const dispatch = useDispatch();
    return (
        <>
            {!!image &&
                <>
                    <img src={`${image}`} alt="Captured" />
                    <Button
                        size={"large"}
                        type={'button'}
                        variant={"contained"}
                        fullWidth={true}
                        className="mt-8"
                        color={"primary"}
                        startIcon={<Cancel />}
                        onClick={() => {
                            setImage('');
                        }}>
                        cancel
                    </Button>
                </>
            }
            {!image && (
                <Webcam
                    audio={false}
                    height={1080}//1280
                    screenshotFormat="image/jpeg"
                    width={720}
                    videoConstraints={{
                        width: 720,
                        height: 1080,
                        facingMode: isFront ? { exact: "environment" } : "user"

                    }}
                >
                    {({ getScreenshot }) => (
                        <div>
                            <Button
                                size={"large"}
                                type={'button'}
                                variant={"contained"}
                                fullWidth={true}
                                className="mt-8"
                                color={"primary"}
                                startIcon={<Camera />}
                                onClick={() => {
                                    const imageSrc = getScreenshot();
                                    setImage(imageSrc);
                                    if (onCapture) {
                                        onCapture(base64ToFile(imageSrc, `${fileName}.jpeg`));
                                        showSuccessAlert(dispatch, "Photo taken!");
                                    }
                                }}>
                                Capture photo
                            </Button>
                            <Button
                                size={"large"}
                                type={'button'}
                                variant={"contained"}
                                fullWidth={true}
                                className="mt-8"
                                color={"primary"}
                                startIcon={<FlipCameraIos />}
                                onClick={() => {
                                    setIsFront(!isFront);
                                }}>
                                Toggle camera
                            </Button>
                        </div>


                    )}
                </Webcam>
            )}
        </>
    )
};

// Convert base64 to File
const base64ToFile = (base64String: string, filename: string): File => {
    const [header, base64Data] = base64String.split(',');
    const mimeType = header.match(/:(.*?);/)?.[1] ?? ''; // Extract mime type from header
    const binaryData = atob(base64Data); // Decode base64 string

    // Convert binary data to an array of bytes
    const byteArray = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
        byteArray[i] = binaryData.charCodeAt(i);
    }

    // Create a Blob from the byte array and mime type
    const blob = new Blob([byteArray], { type: mimeType });

    // Convert the Blob to a File object
    return new File([blob], filename, { type: mimeType });
};

const FileFormInternals: React.FC<{
    resetForm: any;
    values: FileFormValues;
    setFieldValue: any;
    contactId: string;
    jobId: string;
    name: string;
    isSubmitting: boolean;
    isValid: boolean;
}> = ({
    isSubmitting,
    isValid,
    values,
    setFieldValue,
    contactId,
    jobId,
    name,
    resetForm,
}) => {
        const [generatePresignedUrl] = useMutation<
            Mutation,
            MutationGenerateGenericPresignedUrlArgs
        >(GENERATE_GENERIC_PRESIGNED_URL);
        const { files: myFiles } = values;
        const setMyFiles = (acceptedFiles) => setFieldValue("files", acceptedFiles);
        const { onUpload } = useSimpleS3Upload({
            myFiles,
            setMyFiles,
            contactId,
            onError: console.error,
            onUploadDone: async ({ url, fileName }) => {
                setFieldValue("url", url);
                setFieldValue("fileName", fileName);
            },
            onUploadStart: async (img) => {
                /*console.log("img", img)
                resetForm({
                    caption: "",
                    url: "",
                    fileName: "",
                });*/
                const result = await generatePresignedUrl({
                    variables: {
                        path: `contacts/${contactId}/${img}`,
                    },
                });
                return result.data.generateGenericPresignedUrl;
            },
        });

        const files = values.files.map((file: any) => (
            <li key={file.path}>
                {file.path} - {file.size} bytes
            </li>
        ));

        const [selectedPhotoPlace, setsSelectedPhotoPlace] = useState('front');
        useEffect(()=>{
            const file = `${jobId}-${selectedPhotoPlace}-${name}.jpeg`;
            setFieldValue('caption', file);
        },[]);

        const onFileNameChange = (fileName: string) => {
            setsSelectedPhotoPlace(fileName);
            const file = `${jobId}-${fileName}-${name}.jpeg`;
            setFieldValue('caption', file);
        }

        return (
            <Form>
                <Box>
                    <Typography>Take a {name} photo</Typography>
                    <Box className="py-4">
                        <WebcamCapture fileName={name} onCapture={(file: File) => {
                            setFieldValue("caption", file.name);
                            onUpload([file]);
                        }} />
                    </Box>
                    {/*} <Box
                        {...getRootProps({ className: "dropzone bg-black-900 p-4 rounded" })}
                    >
                        <input {...getInputProps()} />
                        <Typography variant="body1">Tap here to upload a file</Typography>
                    </Box>*/}
                    <Box className="py-4">
                        <TextField name={"caption"} label={"Caption"} disabled/>
                    </Box>
                    <Box>
                        <div className="flex flex-row justify-center">
                            <ButtonGroup variant="contained" color="primary">
                                <Button variant={selectedPhotoPlace === 'front'? 'outlined': 'contained'} onClick={()=>onFileNameChange('front')}>Front</Button>
                                <Button variant={selectedPhotoPlace === 'rear'? 'outlined': 'contained'} onClick={()=>onFileNameChange('rear')}>Rear</Button>
                                <Button variant={selectedPhotoPlace === 'side'? 'outlined': 'contained'} onClick={()=>onFileNameChange('side')}>Side</Button>
                                <Button variant={selectedPhotoPlace === 'other'? 'outlined': 'contained'} onClick={()=>onFileNameChange('other')}>Other</Button>
                            </ButtonGroup>
                        </div>
                    </Box>
                    <br />
                    <Box>
                        <h4>Files</h4>
                        <ul>{files}</ul>
                    </Box>
                </Box>
                <div className="flex flex-row justify-between">
                    <div>
                        <SubmitButton
                            label={"Upload"}
                            isSubmitting={isSubmitting}
                            isValid={isValid}
                        />
                    </div>
                </div>
            </Form>
        );
    };

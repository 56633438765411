import * as React from "react";
import { FullScreenDialog, FullScreenDialogProps } from "../../FullScreenDialog";
import { Mutation, MutationCreateFileArgs, Query, QueryGetFilesArgs } from "../../../generated/nest-graphql";
import { GET_FILES } from "../../../graphql/queries/getFiles";
import { CREATE_FILE } from "../../../graphql/mutations/createFile";
import { prop } from "ramda";
import { Paper } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { FileFormValues } from "../../Forms/FileForm";
import { FormikHelpers } from "formik";
import { ShowFiles } from "../ShowFiles";
import { UploadFileSection } from "../UpoadFileSection";
import { showSuccessAlert } from "../../../actions";
import { useDispatch } from "../../../contexts/snackbar-context";
import { useMutation, useQuery } from "@apollo/client";
import { UploadPhotoSection } from "./UploadPhotoSection";

export const PhotoDialog: React.FC<
  FullScreenDialogProps & { contactId: string; name: string, fileCategory?: string; jobId?: string }
> = ({ onClose, open, title, contactId, fileCategory, jobId, name }) => {
  const dispatch = useDispatch();
  const initialValues = {
    caption: "",
    fileName: "",
    url: "",
    files: [],
  };
  const [createFile] = useMutation<Mutation, MutationCreateFileArgs>(CREATE_FILE);
  const onSubmit = async (values, helper: FormikHelpers<FileFormValues>) => {
    await createFile({
      variables: {
        createFileInput: {
          contact: contactId,
          path: `contacts/${contactId}/${values.fileName}`,
          url: values.url,
          share: false,
          fileName: `${values.fileName}`,
          caption: values.caption,
          category: fileCategory,
          jobId: jobId,
        },
      },
      update: (proxy, { data: { createFile } }) => {
        proxy.writeQuery({
          query: GET_FILES,
          variables: { contactId },
          data: {
            getFiles: createFile,
          },
        });
      },
    });
    showSuccessAlert(dispatch, "Success");
    helper.resetForm({
      values: initialValues,
      isSubmitting: false,
    });
    await helper.validateForm(initialValues);
  };
  const { data, startPolling, stopPolling, refetch } = useQuery<Query, QueryGetFilesArgs>(GET_FILES, {
    variables: {
      contactId,
    },
    returnPartialData: true,
    fetchPolicy: "cache-and-network",
  });

  const files = prop("getFiles", data);
  const filesCount = files?.length;

  React.useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open]);

  React.useEffect(() => {
    if (open && filesCount) {
      startPolling(1000 * 300); // 300s = 5min (Signed urls expire time)
    } else {
      stopPolling();
    }
  }, [open, filesCount]);

  React.useEffect(() => {
    return () => {
      stopPolling();
    };
  }, []);

  return (
    <FullScreenDialog title={title} open={open} onClose={onClose}>
      <Paper className="py-4">
        <Container>
          {/*!!prop("length", files) && <ShowFiles files={files} />*/}
          <UploadPhotoSection contactId={contactId} jobId={jobId} name={name} onSubmit={onSubmit} initialValues={initialValues} />
        </Container>
      </Paper>
    </FullScreenDialog>
  );
};

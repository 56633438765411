import * as React from "react";
import { PhotoForm, FileFormValues} from "./PhotoForm";

export const UploadPhotoSection: React.FC<{
  contactId: string;
  jobId: string;
  name: string;
  initialValues: FileFormValues;
  onSubmit: any;
}> = ({ contactId, initialValues, onSubmit, name, jobId }) => {
  return (
    <div>
      <PhotoForm contactId={contactId} jobId={jobId} name={name} initialValues={initialValues} onSubmit={onSubmit} />
    </div>
  );
}
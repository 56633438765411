import React, { useState } from "react";
import { FormItems } from "./forms";
import { Accordion, AccordionDetails, AccordionSummary, Button, IconButton } from "@material-ui/core";
import { CameraAlt, KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { FieldGenerator } from "./FieldGenerator";
import { FilesDialog } from "../../Files/FilesDialog";
import { PhotoDialog } from "../../Files/photos/PhotoDialog";
import { useHistory } from "react-router-dom";

type Props = {
  form: FormItems;
};

export const FormGenerator = ({ form }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const router = useHistory();
  const uiv = (new URLSearchParams(router.location.search)).get('uiv');
  const isPhotoMode = uiv === 'photo';

  const withPhoto = ['frontPads', 'frontRotors', 'rearPads', 'rearRotors', 'brakeFluid', 'rearShoes', 'rearDrums']

  return (
    <>
      {form.map((item, idx) => {
        if (item.itemType === "section") {
          return (
            <div className="grid grid-cols-2 gap-4 p-4 border-[1px] rounded-lg mb-4" key={idx}>
              <div className="flex flex-row items-center mb-4 col-span-full">
                {item.title && <h2 className="text-lg">{item.title}</h2>}
                {item.required && (
                  <>
                    <div className="ml-1">Required</div>
                    <div className="ml-4 text-[#D50000]">*</div>
                  </>
                )}
              </div>
              {item.subTitle && <h3 className="col-span-full">{item.subTitle}</h3>}
              {item.children?.length && <FormGenerator form={item.children as FormItems} />}
              {item.children?.length && <div className="col-span-full">{isPhotoMode && withPhoto.includes(item.code) && <FileInputDialog name={item['title'] || item.subTitle} jobId={item['jobId']} contactId={item['contactId']} />}</div>}
              {item.description && <div className="col-span-full">{item.description}</div>}
            </div>
          );
        } else if (item.itemType === "drawer") {
          return (
            <Accordion
              key={idx}
              style={{
                borderRadius: 12,
                border: "1px solid #E5E5E5",
                marginBottom: 16,
              }}
              expanded={isExpanded}
            >
              <AccordionSummary
                className="flex items-center justify-center"
                onClick={() => setIsExpanded((expanded) => !expanded)}
              >
                <div className="flex items-center">{item.title && <p>{item.title}</p>}</div>

                <div>
                  <IconButton>{isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="grid flex-col w-full">
                  {item.children?.length && <FormGenerator form={item.children as FormItems} />}
                </div>
              </AccordionDetails>
            </Accordion>
          );
        } else if (item.itemType === "field") {
          return <div className="flex flex-col">
            <FieldGenerator key={idx} field={item} />
          </div>;
        }
      })}
    </>
  );
};


const FileInputDialog = ({ name, contactId, jobId }: { name: string, contactId: string, jobId: string }) => {
  const [photoOpen, setPhotoOpen] = useState(false);


  return (<div className="mb-4">
    <Button
      size={"large"}
      type={'button'}
      variant={"contained"}
      fullWidth={true}
      className="mt-8"
      color={"primary"}
      startIcon={<CameraAlt />}
      onClick={() => {
        if (!photoOpen) {
          setPhotoOpen(true)
        }

      }}>Take {name} image</Button>

    <PhotoDialog
      contactId={contactId}
      name={name}
      title="Upload Picture"
      open={photoOpen}
      onClose={() => {
        setPhotoOpen(false);
      }}
      jobId={jobId}
    //fileCategory={VIN_FILE_CATEGORY}
    />
  </div>)
}